<template>
  <div >
    <el-dialog class="regin" center :title="title" width="500px" append-to-body :visible.sync="dialogFormVisible">
      <el-form :model="form" :rules="formRules">
        <!-- <el-form-item label="版本选择" :label-width="formLabelWidth">
            <el-checkbox v-model="checked3" label="社区版" border></el-checkbox>
            <el-checkbox v-model="checked4" label="企业版" border></el-checkbox>
        </el-form-item> -->
        <el-form-item prop="" :label="switchStatus==0?'用户名':switchStatus==1?'Username':'Имя пользователя'" :label-width="formLabelWidth">
          <el-input v-model="form.name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item prop="" :label="switchStatus==0?'手机号码':switchStatus==1?'Phone':'Телефон'" :label-width="formLabelWidth">
          <el-input v-model="form.mobile" auto-complete="off">
             <div v-if="!switchStatus" slot="prepend" class="Codeph" @click="getPhonecode">
              {{addcode}}
              <i class="el-icon-caret-bottom"></i>
            </div>
          </el-input>
        </el-form-item>
        <el-form-item :label="switchStatus==0?'验证码':switchStatus==1?'Code':'Код'" :label-width="formLabelWidth">
          <div class="Code">
              <el-input class="inputs" v-model="form.inputCode" auto-complete="off"></el-input>
              <el-button v-if="switchStatus==0" class="butom" size="small" plain type="primary" @click="smsCode" :disabled="!disabledCodeBtn">获取验证码</el-button>
              <el-button v-if="switchStatus==1" class="butom" size="small" plain type="primary" @click="smsCode" :disabled="!disabledCodeBtn">Get code</el-button>
			  <el-button v-if="switchStatus==2" class="butom" size="small" plain type="primary" @click="smsCode" :disabled="!disabledCodeBtn">Запросить код</el-button>
          </div>
          <span v-show="!show" class="count">{{count}} 秒后重新获取</span>
        </el-form-item>
        <el-form-item prop="" :label="switchStatus==0?'重置密码':switchStatus==1?'New password':'Новый пароль'" :label-width="formLabelWidth">
          <el-input type="password" v-model="form.passwordFst" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item prop="" :label="switchStatus==0?'确认密码':switchStatus==0?'Confirm password':'Подтвердить пароль'"  :label-width="formLabelWidth">
          <el-input type="password" v-model="form.passwordSnd" auto-complete="off"></el-input>
        </el-form-item>        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogFormVisible = false">取 消</el-button> -->
        <el-button type="primary" v-if="switchStatus==0" @click="forgetPassword">确 定</el-button>
        <el-button type="primary" v-if="switchStatus==1" @click="forgetPassword">Submit</el-button>
		<el-button type="primary" v-if="switchStatus==2" @click="forgetPassword">Применить</el-button>
      </div>
      <div class="domep" v-show="phflag">
          <!-- <div class="tipls">
            <p>请选择区号</p>
            <p class="ticp" @click="handount"><i class="el-icon-close"></i></p>
          </div> -->
          <ul>
            <li @click="handPhonecode(item)" v-for="item in list" :key="item.code">
              <span>{{item.city}}</span>
              <span>{{item.code}}</span>
            </li>
          </ul>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import methodlist from '../../../utils/methods'
export default {
  name: 'Regest',
  data () {
    return {
      phflag:false,
      addcode:'+86',
      title: '找回密码',
      form: {
        name: '',
        mobile: '',
        passwordFst:'',
        passwordSnd:'',
        inputCode:'',
        smscode:''
      },

      dialogFormVisible: false,
      disabledCodeBtn:true,
      formLabelWidth: '120px',
      formRules: { 
      },
      show: true,
      count: '',
      timer: null,
      message:'手机号码输入有误',
      // list:,
    }
  },
  props:{
    langflag:Number
  },
  computed: {
    switchStatus: function () {
      return this.langflag // 直接监听props里的status状态
    },
     list:function(){
      return[
          {
              "city": this.$t('zgdl'),
              "code": "+86"
          },
          {
              "city": this.$t('zgxg'),
              "code": "+852"
          },
          {
              "city": this.$t('zgam'),
              "code": "+853"
          },
          {
              "city": this.$t('zgtw'),
              "code": "+886"
          },
          {
            "city": this.$t('afh'),
            "code": "+93"
            },
            {
                "city": this.$t('ade'),
                "code": "+376"
            },
            {
                "city": this.$t('alq'),
                "code": "+971"
            },
            {
                "city": this.$t('agt'),
                "code": "+54"
            },
            {
                "city": this.$t('aejly'),
                "code": "+213"
            },
            {
                "city": this.$t('am'),
                "code": "+968"
            },
            {
                "city": this.$t('ymny'),
                "code": "+374"
            },
            {
                "city": this.$t('alb'),
                "code": "+297"
            },
            {
                "city": this.$t('adl'),
                "code": "+43"
            },
            {
                "city": this.$t('asbj'),
                "code": "+994"
            },
            {
                "city": this.$t('bhm'),
                "code": "+1242"
            },
            {
                "city": this.$t('bl'),
                "code": "+973"
            },
            
            {
                "city": this.$t('bbds'),
                "code": "+1246"
            },
            {
                "city": this.$t('blst'),
                "code": "+970"
            },
            {
                "city": this.$t('bels'),
                "code": "+375"
            },
            {
                "city": this.$t('bls'),
                "code": "+32"
            },
            {
                "city": this.$t('bjst'),
                "code": "+92"
            },
            {
                "city": this.$t('cxx'),
                "code": "+850"
            },
            {
                "city": this.$t('cdjny'),
                "code": "+240"
            },
            {
                "city": this.$t('dg'),
                "code": "+49"
            },
            {
                "city": this.$t('dm'),
                "code": "+45"
            },
            {
                "city": this.$t('jbt'),
                "code": "+253"
            },
            {
                "city": this.$t('dmnjghg'),
                "code": "+1890"
            },
            {
                "city": this.$t('els'),
                "code": "+7"
            },
            {
                "city": this.$t('egde'),
                "code": "+593"
            },
            {
                "city": this.$t('aj'),
                "code": "+20"
            },
            {
                "city": this.$t('aseby'),
                "code": "+251"
            },
            {
                "city": this.$t('fg'),
                "code": "+33"
            },
            {
                "city": this.$t('fl'),
                "code": "+358"
            },
            {
                "city": this.$t('flb'),
                "code": "+63"
            },
            {
                "city": this.$t('fdg'),
                "code": "+379"
            },
            {
                "city": this.$t('gby'),
                "code": "+220"
            },
            {
                "city": this.$t('ggghg'),
                "code": "+242"
            },
            {
                "city": this.$t('glby'),
                "code": "+57"
            },
            {
                "city": this.$t('gba'),
                "code": "+53"
            },
            {
                "city": this.$t('gljy'),
                "code": "+995"
            },
            {
                "city": this.$t('hg'),
                "code": "+82"
            },
            {
                "city": this.$t('hl'),
                "code": "+31"
            },
            
            {
                "city": this.$t('hd'),
                "code": "+509"
            },
          {
                "city": this.$t('jbz'),
                "code": "+855"
            },
            {
                "city": this.$t('jlbs'),
                "code": "+686"
            },
            {
                "city": this.$t('jny'),
                "code": "+224"
            },
            {
                "city": this.$t('jbbw'),
                "code": "+263"
            },
            {
                "city": this.$t('kml'),
                "code": "+237"
            },
            {
                "city": this.$t('kmqt'),
                "code": "+1345"
            },
            {
                "city": this.$t('kny'),
                "code": "+254"
            },
            {
                "city": this.$t('kwt'),
                "code": "+965"
            },
            {
                "city": this.$t('kldy'),
                "code": "+385"
            },
            {
                "city": this.$t('kte'),
                "code": "+974"
            },
          {
                "city": this.$t('lys'),
                "code": "+856"
            },
            {
                "city": this.$t('lby'),
                "code": "+218"
            },
            {
                "city": this.$t('lwd'),
                "code": "+250"
            },
            {
                "city": this.$t('md'),
                "code": "+95"
            },
            {
                "city": this.$t('mlg'),
                "code": "+212"
            },
            {
                "city": this.$t('mg'),
                "code": "+1"
            },
            {
                "city": this.$t('mlxy'),
                "code": "+60"
            },
            
            {
                "city": this.$t('mxg'),
                "code": "+52"
            },
            {
                "city": this.$t('nf'),
                "code": "+27"
            },
            {
                "city": this.$t('nbe'),
                "code": "+977"
            },
            {
                "city": this.$t('nrly'),
                "code": "+234"
            },
            {
                "city": this.$t('nw'),
                "code": "+47"
            },
            {
                "city": this.$t('plqd'),
                "code": "+680"
            },
            {
                "city": this.$t('pty'),
                "code": "+351"
            },
            {
                "city": this.$t('rb'),
                "code": "+81"
            },
            {
                "city": this.$t('rd'),
                "code": "+46"
            },
            {
                "city": this.$t('rs'),
                "code": "+41"
            },
            {
                "city": this.$t('stalb'),
                "code": "+966"
            },
            {
                "city": this.$t('sd'),
                "code": "+249"
            },
            {
                "city": this.$t('slxy'),
                "code": "+1758"
            },
             {
                  "city": this.$t('tgs'),
                  "code": "+66"
              },
              {
                  "city": this.$t('tjs'),
                  "code": "+676"
              },
            
            {
                "city": this.$t('teq'),
                "code": "+90"
            },
            {
                "city": this.$t('tns'),
                "code": "+216"
            },
            {
                "city": this.$t('wnrl'),
                "code": "+58"
            },
            {
                "city": this.$t('wl'),
                "code": "+673"
            },
            {
                "city": this.$t('wkl'),
                "code": "+380"
            },
            {
                "city":this.$t('xxl'),
                "code": "+64"
            },
            {
                "city": this.$t('xjb'),
                "code": "+65"
            },
            {
                "city": this.$t('xby'),
                "code": "+34"
            },
            {
                "city": this.$t('xl'),
                "code": "+30"
            },
            {
                "city": this.$t('xly'),
                "code": "+963"
            },
            {
                "city": this.$t('xyl'),
                "code": "+36"
            },
            {
                "city": this.$t('ydl'),
                "code": "+39"
            },
            {
                "city": this.$t('yg'),
                "code": "+44"
            },
            {
                "city": this.$t('ydnxy'),
                "code": "+62"
            },
            {
                "city": this.$t('yd'),
                "code": "+91"
            },
            {
                "city": this.$t('yl'),
                "code": "+98"
            },
            {
                "city": this.$t('ylk'),
                "code": "+964"
            },
            {
                "city": this.$t('ysl'),
                "code": "+972"
            },
            {
                "city": this.$t('yn'),
                "code": "+84"
            },
            {
                "city": this.$t('ym'),
                "code": "+967"
            },
            
            {
                "city": this.$t('zl'),
                "code": "+56"
            },
            {
                "city": this.$t('zfghg'),
                "code": "+236"
            },

      ]
    }
  },
  watch:{
    'switchStatus':function(nv,ol){
      if(nv==0){
        this.message = '手机号码输入有误'
        this.title='找回密码'
        this.$i18n.locale = 'cn'
      } else if(nv==1){
        this.message = 'Wrong input of mobile phone number'
        this.title='Password recovery'
        this.$i18n.locale = 'en'
      }else{
		this.title='Восстановление пароля'
	  }
    }
  },
  methods:{
    handount(){
      this.phflag = false
    },
    getPhonecode(){
      this.phflag = !this.phflag
    },
    handPhonecode(item){
      console.log(item.code)
      this.addcode =item.code
      this.phflag = false
    },
    smsCode:function(){
      if(!this.form.name){
        this.$message({
          type: 'error',
          message: this.$t('qtxyhm')
        });
        return false
      }
      // if (!/^1[3456789]\d{9}$/.test(this.form.mobile)){
      //     this.$message({
      //         message: this.message,
      //         type: 'warning'
      //     });
      //     return false;
      // }
      // let mobiles = this.addcode + '-' + this.form.mobile
      let mobiles = ""
      if(this.addcode!='+86'){
         mobiles = this.addcode + '-' + this.form.mobile
      }else{
         mobiles =  this.form.mobile
      }
 
      const params = {
        method: methodlist.ee_sms_code,
        name: this.form.name, 
        mobile: mobiles,
        type: '1'
      }
      console.log(params);
      this.$serve(params).then(res => {
        console.log(res)
        if(res.data.code == 0){
            this.form.smscode = res.data.data.verify_code;
        }else{
          if(res.data.code == -3){
            this.$message({
              type: 'warning',
              message: this.$t('yhmycz')
            });
          }else{
            this.$message({
              type: 'warning',
              message: this.$t('hqsb')
            });
          }
        }
        
      })

      const TIME_COUNT = 60;
      this.disabledCodeBtn = false;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            this.disabledCodeBtn = true;
          }
        }, 1000)
      }
    },
    
    forgetPassword:function(){
      if(this.form.name.length ==0 ){
          this.$message({
            type: 'warning',
            message: this.$t('yhmbnwk')
          });
          return false;
      }

      if(this.form.passwordFst.length ==0 ){
          this.$message({
            type: 'warning',
            message: this.$t('mmbnwk')
          });
          return false;
      }

      if(this.form.passwordFst != this.form.passwordSnd){
          this.$message({
            type: 'warning',
            message: '密码输入不一致!'
          });
          return false;        
      }

      if(this.form.mobile.length ==0 ){
          this.$message({
            type: 'warning',
            message: this.$t('sjhmbnwk')
          });
          return false;
      }

      if(this.form.inputCode.length ==0 ){
          this.$message({
            type: 'warning',
            message:this.$t('yzmbnwk')
          });
          return false;
      }
      
      let mobiles = ""
      if(this.addcode!='+86'){
        mobiles = this.addcode + '-' + this.form.mobile
      }else{
        mobiles =  this.form.mobile
      }
      const params = {
          method: methodlist.ee_reset_pwd,
          name: this.form.name,
          mobile: mobiles, 
          password: this.$md5(this.form.passwordFst),
          verify_code:this.form.inputCode    
      }

      this.$serve(params).then(res => {
        console.log(res)
        if(res.data.code == 0){
          this.$message({
            type: 'success',
            message: '密码修改成功!'
          })          
          this.dialogFormVisible = false;
        }else{
          var mess_hint ='密码修改失败';
          if(res.data.code == -3){
            mess_hint = ' 用户不存在';
          }else if(res.data.code == -7){
            mess_hint = ' 验证码不正确';
          }
          this.$message({
            type: 'warning',
            message: mess_hint               
          });
        }
      })
    }

  }
}
</script>
<style lang="less" scoped>

.eregin .el-dialog /deep/ .el-dialog__header{
  border-bottom: 1px solid #000;
}
.el-form{
  width: 85%;
  .Code{
    width: 100%;
    display: flex;
    .inputs{
      flex:3;
      margin-right: 20px;
    }
    .butom{
      flex: 1;
    }
  }
  .count{
    font-size: 20px;
    color: red;
  }
}
.Codeph{
  position: relative;
  cursor: pointer;
}
.domep{
  width: 230px;
  height: 340px;
  border-radius: 5px;
  padding:0 18px 18px 18px;
  border: 1px solid #ccc;
  position: absolute;
  top: 125px;
  left: 142px ;
  background-color:white ;
  box-shadow:0px 0px 6px rgb(0 0 0 / 20%);
  color: #444444;
  overflow: hidden;
  overflow:auto
}
.ticp{
  cursor: pointer;
  font-size: 18px;
}
.tipls{
  height: 40px ;
  line-height: 40px;
  border-bottom:1px solid #ccc ;
  display: flex;
  justify-content: space-between;
}
.domep ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
  
}
.domep ul li{
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 0 10px;
  cursor: pointer;
}
</style>
