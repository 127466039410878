<template>
  <div class="login-container">
    <div class="login-form-wrap">
      <div class="login-head">
        <div class="logo">
          <h1 v-if="langflag==0">用户登录</h1>
          <h1 v-if="langflag==1">Autorisation</h1>
		  <h1 v-if="langflag==2">Авторизация</h1>
        </div>
      </div>

      <el-form
        class="login-form"
        ref="login-form"
        :model="user"
        :rules="formRules"
      >
        <el-form-item prop="mobile">
          <el-input
            v-model="user.name"
            :placeholder="langflag==0? '请输入用户名':langflag==1?'Username':'Имя пользователя'"
          ></el-input>
        </el-form-item>

        <el-form-item prop="code" > 
          <el-input
            type='password'
            v-model="user.password"
            :placeholder="langflag==0?'请输入密码':langflag==1?'Password':'Пароль'"
          ></el-input>
        </el-form-item>

        <el-form-item style="width:100px">
          <el-select v-model="user.lang" placeholder="中文" @change="handang">
          <el-option :label="langflag==0?'中文':langflag==1?'Chinese':'китайский язык'" value="cn"></el-option>
          <el-option :label="langflag==0?'English':langflag==1?'English':'английский'" value="en"></el-option>
		  <el-option :label="langflag==0?'俄文':langflag==1?'Russian':'русский язык'" value="re"></el-option>
        </el-select>
        </el-form-item>

        <div class="regents_content">
          <el-form-item prop="agree">
            <!-- <el-checkbox v-model="user.agree">记住密码</el-checkbox> -->
            <!-- <p class="foutwrod" @click="HandlePassword">找回密码</p> -->
            <p @click="HandlePassword" v-if="langflag==0">找回密码</p>
            <p @click="HandlePassword" v-if="langflag==1">Password recovery</p>
			<p @click="HandlePassword" v-if="langflag==2">Восстановить пароль</p>
          </el-form-item>
          
          <p class="regest" @click="HandleRegest" v-if="langflag==0">注册</p>
          <p class="regest" @click="HandleRegest" v-if="langflag==1">Register</p>
		  <p class="regest" @click="HandleRegest" v-if="langflag==2">Регистрация</p>
        </div>
        <el-form-item>
          <el-button
            class="login-btn"
            type="primary"
            :loading="loginLoading"
            @click="onLogin"
          ><p v-if="langflag==0">登录</p>
          <p v-if="langflag==1">Sign in</p>
		  <p v-if="langflag==2">Вход</p>
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 注册子组件 -->

    <Regest class="regin" :langflag="this.langflag" ref="regest"></Regest>
    <Password class="regin" ref="password" :langflag="this.langflag"></Password>
  </div>
</template>

<script>
import Regest from './components/regest'
import Password from './components/password'
import methodlist from '../../utils/methods'

export default {
  name: 'LoginIndex',
  components: {
    Regest,
    Password
  },
  props: {},
  data () {
    return {
      user: {
        name: '', // 手机号
        password: '',// 验证码
        lang:'cn'
      },
      langflag:1,
      // checked: false, // 是否同意协议的选中状态
      loginLoading: false, // 登录的 loading 状态
      formRules: { // 表单验证规则配置
        // 要验证的数据名称：规则列表[]
        name: [
          // trigger
          { required: true, message: '请输入手机号', trigger: 'blur' },
          // { pattern: /^1[3|5|7|8|9]\d{9}$/, message: '请输入正确的号码格式', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
          // { pattern: /^\d{6}$/, message: '请输入正确的验证码格式', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {},
  watch: {
    'user.lang':function(nv,ol){
      if(nv=='en'){
        this.langflag = 1
      }else if(nv=='cn'){
        this.langflag= 0
      }else{
		  this.langflag= 2
	  }
	  console.log(nv);
      localStorage.setItem('language', nv)
    }
  },
  beforeCreate() {
  	
  },
  created () {
	  if(navigator.language=='zh-CN'){
	  	this.langflag = 0
	  }else if(navigator.language=='zh'){
	  	this.langflag = 0
	  }else if(navigator.language=='zh-TW'){
	  	this.langflag = 0
	  }else if(navigator.language=='zh-HK'){
	  	this.langflag = 0
	  }else{
	  	this.langflag = 1
	  }
  },
  mounted () {
    window.localStorage.removeItem("user");
	console.log(this.langflag);
  },
  methods: {
    handang(val){
      this.user.lang =val

    },
    async onLogin () {      
      const user = {
        name: this.user.name, // 手机号
        password: this.$md5(this.user.password),
        method: methodlist.ee_login
      }
      localStorage.setItem('language', this.user.lang )
      this.$serve(user).then(res => {
				console.log('wolail');
        if(res.data.code == 0){
          if(res.data.inMaintenance==0 || this.user.name=='ework'){
              res.data.data.user_name_save= this.user.name;
              res.data.data.user_pass_save = this.user.password;
              window.localStorage.setItem('user', JSON.stringify(res.data.data))
              // if (res.data) {
              //   this.$router.push({ path: '/home' })
                
              // }
				if(res.data.data.is_agent_admin==1){
					this.$router.push({path:'/communitylist'})
				}else{
					this.$router.push({ path: '/home' })
				}
							
          }else{
              this.$message({
                message: '系统正在维护，请稍后登录。。。。',
                type: 'error'
              });
          }
          
        }else{
          if(res.data.code == -3){
              this.$message({
                message: '用户名不存在，',
                type: 'error'
              });
          }else if(res.data.code == -6){
              this.$message({
                message: '密码错误',
                type: 'error'
              });
          }else{
              this.$message({
                message: '登录失败',
                type: 'error'
              });
          }
        }

      })
    },
    login () {
    },
    HandleRegest () {
      this.$refs.regest.dialogFormVisible = true     
    },
    HandlePassword(){
      this.$refs.password.dialogFormVisible = true
    }
  }
}
</script>

<style scoped lang="less">
.login-container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("./login_bg.jpg") no-repeat;
  background-size: cover;
  .login-form-wrap {
    min-width: 300px;
    padding: 30px 50px 10px;
    background-color: #fff;
    .regents_content{
      display: flex;
      .el-form-item{
        flex: 1;
      }
      p{
        margin:0
      }
      .foutwrod{
        flex: 1;
        text-align: right;
        line-height: 40px;
        color: #606266;
        font-size: 14px;
        cursor: pointer;
      }
      .regest{
        flex: 1;
        text-align: right;
        line-height: 40px;
        color: #606266;
        font-size: 14px;
        cursor: pointer;
      }
    }
    .login-head {
      display: flex;
      justify-content: center;
      .logo {
        width: 200px;
        background-size: contain;
        h1{
          margin: 0;
          text-align: center;
          margin-bottom: 30px;
        }
      }
    }
    .login-form {
      .login-btn {
        width: 100%;
      }
    }
  }
}
.regents_content .el-checkbox /deep/ el-checkbox__label{
  font-size: 16px;
}
.el-dialog__wrapper .el-dialog /deep/ .el-dialog__header{
  border-bottom: 1px solid #000;
}
</style>
